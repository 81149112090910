import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { ArtistList } from '../components/artists';
import { Layout, SliceZone } from '../components';

class Artists extends Component {
  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
        artists,
      },
      location,
    } = this.props;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          allSlices={body}
          location={location}
        />
        <ArtistList
          artists={artists}
        />
      </Layout>
    );
  }
}

export default Artists;

export const pageQuery = graphql`
  query ArtistsQuery {
    page: prismicArtists {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicArtistsBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              description {
                text
              }
              illustration {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                alt
              }
              theme
            }
          }
        }
      }
    }
    artists: allPrismicArtist (
      sort: { fields: [data___name___text], order: DESC }
    ) {
      edges {
        node {
          data {
            featuredPiece: featured_piece {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid_withWebp
              }
              alt
            }
            featuredPieceSize: featured_piece_size
            featuredPieceOrientation: feature_piece_orientation
            backgroundColour: background_colour
            name {
              text
            }
            portfolioLink: portfolio_link {
              url
              target
              linkType: link_type
            }
          }
          uid
        }
      }
    }
  }
`;
