import React from 'react';
import {
  Wrapper,
  Image,
} from '../common';
import { convertColorToTheme } from '../../utils/helpers';
import './ArtistList.scss';

const ArtistListItem = (props) => {
  const {
    data,
    uid,
  } = props;
  const {
    featuredPiece,
    featuredPieceSize,
    featuredPieceOrientation,
    backgroundColour,
    name,
    portfolioLink,
  } = data;
  let illustrationClasses = 'illustration';
  if (backgroundColour) {
    illustrationClasses = `${illustrationClasses} theme-${convertColorToTheme(backgroundColour.toLowerCase())}`;
  }
  if (featuredPieceSize) illustrationClasses = `${illustrationClasses} ${featuredPieceSize.toLowerCase()}`;
  if (featuredPieceOrientation) illustrationClasses = `${illustrationClasses} ${featuredPieceOrientation.toLowerCase()}`;
  return (
    <a
      className="artist"
      href={portfolioLink.url}
      target="_blank"
      rel="noopener noreferrer"
      key={uid}
      aria-label={`Find more about the ${name.text}`}
    >
      <div className={illustrationClasses}>
        <div className="image-container">
          <Image image={featuredPiece} />
        </div>
      </div>
      <h3 className="artist-title">{name.text}</h3>
    </a>
  );
};

const ArtistList = (props) => {
  const {
    artists,
  } = props;
  if (!artists || artists.length < 1) return null;
  const artistListItems = artists && artists.edges.map(artist => artist.node);
  return (
    <section className="artist-list">
      <Wrapper isBound>
        <div className="artists">
          {artistListItems.map(artist => (
            <ArtistListItem
              key={artist.slug}
              {...artist}
            />
          ))}
        </div>
      </Wrapper>
    </section>
  );
};

export default ArtistList;
